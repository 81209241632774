(function () {
  if (!window.Uri) {
    // see: https://tools.ietf.org/html/rfc3986
    const regexUri = /^(([a-z][a-z0-9+.-]*):)?(\/\/(([!$&\\'()*,;=a-z0-9._~-]|%[0-9a-f][0-9a-f])*)(\:([0-9]+))?)?(([\/!$&\\'()*,;=:@a-z0-9._~-]|%[0-9a-f][0-9a-f])*)(\?([!$&\\'()*,;=:@a-z0-9._~\/?-]|%[0-9a-f][0-9a-f])*)?(\#.*)?$/i;

    const parseQuery = (params: Record<string, string|null>, raw: string) => {
        raw
          .split('&')
          .forEach(one => {
            if (one) {
              const i = one.indexOf('=');
              if (-1 === i) {
                params[decodeURIComponent(one)] = null;
              } else {
                params[decodeURIComponent(one.substring(0, i))] = decodeURIComponent(one.substring(i + 1));
              }
            }
          });
    };

    const parse = (uri: TypeOfUri, raw: string) => {
      const m = regexUri.exec(raw);
      if (m) {
        uri.scheme = m[2];
        uri.host = m[4];
        uri.path = m[8];
        uri.port = parseInt(m[7], 10) || window.Uri.defaultPorts[uri.scheme] || 0;
        uri.query = (m[10] && m[10].substr(1) || '');
        uri.fragment = (m[12] && m[12].substr(1) || '');
      }
    };

    const enc = (input: string|null) => input ? encodeURIComponent(input) : '';

    /**
     * Simple and straightforward Uri wrapper.
     */
    window.Uri = class Uri implements TypeOfUri {
      static readonly defaultPorts = {
        ftp: 21,
        http: 80,
        https: 443
      };

      /**
       * Alias for {@link window.Uri.from}.
       *
       * @deprecated Use {@link window.Uri.from} instead.
       */
       static create (uri: string|TypeOfUri): TypeOfUri {
        if (uri instanceof window.Uri) {
          return <any> uri;
        }
        return new window.Uri(<any> uri);
      }

      /**
       * Creates {@link window.Uri} form an argument.
       */
       static from (uri: string|TypeOfUri) {
        return window.Uri.create(uri);
      }

      /**
       * Gets or sets scheme of the Uri.
       */
      scheme?: string;
      /**
       * Gets or sets hostname of the Uri.
       */
      host?: string;
      /**
       * Gets or sets path component of the Uri.
       */
      path?: string;
      /**
       * Gets or sets port of the Uri.
       */
      port?: number;
      /**
       * Gets or sets fragment of the Uri.
       */
      fragment?: string;
      /**
       * Gets or sets query component of the Uri as object. Allows accessing and manipulating individual arguments
       * within query component.
       */
      queryParams!: Record<string, string|null>;

      constructor(raw: string) {
        parse(this, raw);
      }
      /**
       * Is _true_ if the wrapper Uri is relative.
       */
      get isRelative () {
        return !this.scheme;
      }
      /**
       * Is _true_ if the wrapper Uri is absolute.
       */
      get isAbsolute () {
        return !!this.scheme;
      }
      /**
       * Gets or sets authority of the Uri (i.e. hostname and port if non standard).
       */
      get authority () {
        if (this.port && (!this.scheme || this.port !== window.Uri.defaultPorts[this.scheme])) {
          return `${this.host}:${this.port}`;
        }
        return this.host;
      }

      set authority (authority: string|undefined) {
        if (!authority) {
          this.host = authority;
          this.port = 0;
        } else {
          const i = authority.indexOf(':');
          if (-1 === i) {
            this.host = authority;
            this.port = 0;
          } else {
            this.host = authority.substr(0, i);
            this.port = parseInt(authority.substr(i + 1), 10) || 0;
          }
        }
      }
      /**
       * Gets or sets the wrapped Uri.
       *
       * @type {String}
       */
      get href () {
        const query = this.query;
        const queryString = query ? `?${query}` : '';
        const fragment = this.fragment ? `#${this.fragment}` : '';
        const authority = this.authority ? `//${this.authority}` : '';
        const scheme = this.scheme ? `${this.scheme}:` : '';
        return `${scheme}${authority}${this.path}${queryString}${fragment}`;
      }
      set href (href) {
        parse(this, href);
      }
      /**
       * Gets or sets query component of the Uri. To access or manipulate individual query arguments use
       * {@link window.Uri#queryParams}.
       *
       * @type {String}
       */
      get query () {
        const queryParams = this.queryParams || {};
        return Object.keys(queryParams)
          .map(key => `${encodeURIComponent(key)}=${enc(queryParams[key])}`)
          .join('&');
      }
      set query (query) {
        this.queryParams = {};
        parseQuery(this.queryParams, query);
      }
      toString () {
        return this.href;
      }
    };
  }
}());

(function () {

  const scrollHandlers: DotNetRef[] = [];
  let pageY = 0;
  let lastPageY = -1;

  const scrollHandler = () => {
    window.requestAnimationFrame(scrollHandler);
    if (pageY !== lastPageY) {
      lastPageY = pageY;
      for (const handler of scrollHandlers) {
        // NOTE: intentionally not awaited
        handler.invokeMethodAsync('JsOnScroll', pageY);
      }
    }
  };
  window.requestAnimationFrame(scrollHandler);

  window.addEventListener('scroll', () => {
    pageY = window.pageYOffset;
  }, false);

  const ownerMap = new WeakMap<HTMLElement, { owner: HTMLElement; settings?: DropDownSettings }>();

  const reposition = (e: HTMLElement, { owner, settings }: { owner: HTMLElement; settings?: DropDownSettings }) => {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    const rect = owner.getBoundingClientRect();
    // vertical position + height;
    if (rect.top > (vh - rect.bottom)) {
      // open above the owner reversed
      e.setAttribute('reversed', '');
      e.style.top = 'auto';
      e.style.left = `${rect.left}px`;
      e.style.bottom = `${vh - rect.top}px`;
      if (settings && settings.fixHeight) {
        e.style.height = 'number' === typeof settings.fixHeight ? `${settings.fixHeight}px` : settings.fixHeight;
        e.style.overflow = 'visible';
      } else {
        e.style.maxHeight = `${rect.top}px`;
        e.style.removeProperty('overflow');
      }
    } else {
      e.removeAttribute('reversed');
      e.style.top = `${rect.bottom}px`;
      e.style.bottom = 'auto';
      if (settings && settings.fixHeight) {
        e.style.height = 'number' === typeof settings.fixHeight ? `${settings.fixHeight}px` : settings.fixHeight;
        e.style.overflow = 'visible';
      } else {
        e.style.maxHeight = `${vh - rect.bottom}px`;
        e.style.removeProperty('overflow');
      }
    }
    // horizontal position
    const halign = settings && settings.halign;
    if (!halign || halign === 'left') {
      e.style.left = `${rect.left}px`;
      e.style.right = 'auto';
    } else if (halign === 'right') {
      e.style.right = `${vw - rect.right}px`;
      e.style.left = 'auto';
    } else {
      e.style.left = `${rect.left + (rect.width / 2)}px`;
    }
  };

  window.veb2023 = window.veb2023 || {};
  window.veb2023.showDropDown = (e: HTMLElement, owner?: HTMLElement, settings?: DropDownSettings) => {
    if (owner) {
      ownerMap.set(e, { owner, settings });
      reposition(e, { owner, settings });
    }
    e.hidden = false;
  };
  window.veb2023.hideDropDown = (e: HTMLElement) => {
    e.hidden = true;
  };
  window.veb2023.updateDropDown = (e: HTMLElement) => {
    const owner = ownerMap.get(e);
    if (owner && !e.hidden) {
      reposition(e, owner);
    }
  };
  window.veb2023.setValue = (e: HTMLInputElement, value: string) => {
    e.value = value;
  };

  window.veb2023.registerScrollHandler = (cbObj: DotNetRef) => {
    scrollHandlers.push(cbObj);
  };
  window.veb2023.unregisterScrollHandler = (cbObj: DotNetRef) => {
    const index = scrollHandlers.indexOf(cbObj);
    if (-1 === index) {
      scrollHandlers.splice(index, 1);
    }
  };

  const regexScript = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

  const fbAccessToken = '1130136377479078|9641d08f3a0c2e1ec106d69fc91764a3';

  let pinstgrm: Promise<void>|null = null;

  const loadIntsgrm = () => {
    if (!pinstgrm) {
      pinstgrm = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.onerror = reject;
        script.onload = () => {
          const wait = (nTry: number) => {
            if ((<any> window).instgrm) {
              resolve();
            } else if (nTry > 10) {
              reject('failed to load instagram');
            } else {
              setTimeout(() => wait(nTry + 1), nTry * 50);
            }
          };
          wait(1);
        };
        script.src = '//platform.instagram.com/en_US/embeds.js';
        document.head.appendChild(script);
      });
    }
    return pinstgrm;
  };

  let pfb: Promise<void>|null = null;

  const loadFb = () => {
    if (!pfb) {
      pfb = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.onerror = reject;
        script.onload = () => {
          const wait = (nTry: number) => {
            if ((<any> window).instgrm) {
              resolve();
            } else if (nTry > 10) {
              reject('failed to load instagram');
            } else {
              setTimeout(() => wait(nTry + 1), nTry * 50);
            }
          };
          wait(1);
        };
        script.src = '//connect.facebook.net/en_US/sdk.js';
        document.head.appendChild(script);
      });
    }
    return pfb;
  };

  window.veb2023.initOEmbed = async (target, rawUri, jsonp?: boolean) => {
    if (!rawUri) { return; }
    const uri = window.Uri.from(rawUri);
    uri.queryParams.width = String(target.clientWidth);
    uri.queryParams.maxwidth = String(target.clientWidth);
    uri.queryParams.maxheight = '220';
    let onReady = () => { /* noop */ };
    if (-1 !== uri.host!.search(/facebook/) || -1 !== uri.host!.search(/instagram/)) {
      uri.queryParams.access_token = fbAccessToken;
      uri.queryParams.maxwidth = '320';
      uri.queryParams.omitscript = 'true';
      if ('string' === typeof uri.queryParams.url && -1 !== uri.queryParams.url.search(/instagr\.?am/)) {
        onReady = () => {
          loadIntsgrm().then(() => {
            (<any> window).instgrm.Embeds.process();
          });
        };
      } else {
        uri.queryParams.useiframe = 'true';
      }
    }
    let data: any;
    if (jsonp) {
      const fn = `jsonp_${Date.now()}_${Math.round(Math.random() * 10000)}`;
      uri.queryParams.callback = fn;
      uri.queryParams.jsoncallback = fn;
      data = await new Promise<any>((resolve, reject) => {
        (<any> window)[fn] = resolve;
        const script = document.createElement('script');
        script.async = true;
        script.onerror = reject;
        script.src = uri.href;
        document.head.appendChild(script);
      });
      if ('string' === typeof data.html && -1 === data.html.indexOf('<script')) {
        // remove script tags
        data.html = data.html.replace(regexScript, '');
      }
    } else {
      const response = await fetch(uri.href);
      if (!response.ok) {
        console.error(`failed to load oembed data from ${uri.href}`);
        return;
      }
      data = await response.json();
    }
    if (!data || !data.html) {
      console.error(`got invalid oembed data from ${uri.href}`);
      return;
    }
    target.innerHTML = data.html;
    onReady();
  };

  const beacons: Record<string, { url: string; data?: Record<string, string>; appendAccessToken?: boolean; }> = {};
  const nextBeaconUid = (function() {
    let sup = 0;
    return () => {
      const res = sup;
      sup = sup === Number.MAX_SAFE_INTEGER ? 0 : sup + 1;
      return `beacon-${res}`;
    };
  }());

  let beaconInitialized = false;
  const beacon = () => {
    const keys = Object.keys(beacons).slice(0);
    for (const key of keys) {
      const { url, data, appendAccessToken } = beacons[key];
      const uri = window.Uri.from(url);
      if (appendAccessToken) {
        uri.queryParams.access_token = localStorage.getItem('admin-access-token');
      }
      const body = data
        ? new Blob([JSON.stringify(data)], { type: 'text/plain' })
        : null;
      window.navigator.sendBeacon(uri.href, body);
      delete beacons[key];
    }
  };

  window.veb2023.registerBeacon = (url: string, data?: Record<string, string>, appendAccessToken?: boolean) => {
    if (!beaconInitialized) {
      window.addEventListener('unload', beacon, true);
      beaconInitialized = true;
    }
    const uid = nextBeaconUid();
    beacons[uid] = { url, data, appendAccessToken };
    return uid;
  };
  window.veb2023.unregisterBeacon = (uid: string) => {
    delete beacons[uid];
  };

  window.veb2023.updateIcon = (el: Element, key: string) => {
    if (el) {
      if (el.firstChild instanceof SVGUseElement) {
        const attr = document.createAttributeNS('http://www.w3.org/1999/xlink', 'href');
        attr.value = `#${key}`;
        el.firstChild.attributes.setNamedItemNS(attr);
      } else {
        const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        el.insertBefore(use, el.firstChild);
        const attr = document.createAttributeNS('http://www.w3.org/1999/xlink', 'href');
        attr.value = `#${key}`;
        use.attributes.setNamedItemNS(attr);
      }
    }
  };

  const draggableHandlers = new WeakMap<Element, { onDragStart: (e: DragEvent) => void; onDragEnd: (e: DragEvent) => void }>();

  window.veb2023.draggable = {
    init: (host: Element, cbObj: DotNetRef) => {
      if (host instanceof HTMLElement) {
        const onDragStart = (e: DragEvent) => {
          const dto = cbObj.invokeMethod<{ dropEffect: 'none'|'copy'|'link'|'move' }>('HandleDragStart', e.dataTransfer || {});
          if (dto && e.dataTransfer) {
            e.dataTransfer.dropEffect = dto.dropEffect;
          }
        };
        const onDragEnd = (e: DragEvent) => {
          const dto = cbObj.invokeMethod<{ dropEffect: 'none'|'copy'|'link'|'move' }>('HandleDragEnd', e.dataTransfer || {});
          if (dto && e.dataTransfer) {
            e.dataTransfer.dropEffect = dto.dropEffect;
          }
        };
        host.addEventListener('dragstart', onDragStart, false);
        host.addEventListener('dragend', onDragEnd, false);
        draggableHandlers.set(host, { onDragStart, onDragEnd });
      }
    },
    deinit: (host: Element) => {
      if (host instanceof HTMLElement) {
        const handlers = draggableHandlers.get(host);
        if (handlers) {
          const { onDragStart, onDragEnd } = handlers;
          host.removeEventListener('dragstart', onDragStart, false);
          host.removeEventListener('dragend', onDragEnd, false);
        }
      }
    }
  };

  const dropZoneHandlers = new WeakMap<Element, {
    onDragEnter: (e: DragEvent) => void;
    onDragLeave: (e: DragEvent) => void;
    onDragOver: (e: DragEvent) => void;
    onDrop: (e: DragEvent) => void;
  }>();

  window.veb2023.dropZone = {
    init: (host: Element, cbObj: DotNetRef) => {
      if (host instanceof HTMLElement) {
        const onDragEnter = (e: DragEvent) => {
          e.preventDefault();
          host.setAttribute('targeted', '');
          cbObj.invokeMethod('HandleDragEnter');
        };
        const onDragLeave = (e: DragEvent) => {
          e.preventDefault();
          host.removeAttribute('targeted');
          cbObj.invokeMethod('HandleDragLeave');
        };
        const onDragOver = (e: DragEvent) => {
          e.preventDefault();
        };
        const onDrop = (e: DragEvent) => {
          e.preventDefault();
          cbObj.invokeMethod('HandleDrop');
        };
        host.addEventListener('dragenter', onDragEnter, false);
        host.addEventListener('dragleave', onDragLeave, false);
        host.addEventListener('dragover', onDragOver, false);
        host.addEventListener('drop', onDrop, false);
        dropZoneHandlers.set(host, { onDragEnter, onDragLeave, onDragOver, onDrop });
      }
    },
    deinit: (host: Element) => {
      if (host instanceof HTMLElement) {
        const handlers = dropZoneHandlers.get(host);
        if (handlers) {
          const { onDragEnter, onDragLeave, onDragOver, onDrop } = handlers;
          host.removeEventListener('dragenter', onDragEnter, false);
          host.removeEventListener('dragleave', onDragLeave, false);
          host.removeEventListener('dragover', onDragOver, false);
          host.removeEventListener('drop', onDrop, false);
        }
      }
    }
  };

  window.veb2023.openWindow = (path: string) => {
    window.open(path, '_blank');
  };

  // Convert a base64 string to a Uint8Array. This is needed to create a blob object from the base64 string.
  // The code comes from: https://developer.mozilla.org/fr/docs/Web/API/WindowBase64/D%C3%A9coder_encoder_en_base64
  const b64ToUint6 = (nChr: number) => {
    return nChr > 64 && nChr < 91 ? nChr - 65 : nChr > 96 && nChr < 123 ? nChr - 71 : nChr > 47 && nChr < 58 ? nChr + 4 : nChr === 43 ? 62 : nChr === 47 ? 63 : 0;
  };

  const base64DecToArr = (sBase64: string, nBlocksSize?: number) => {
    const sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, '');
    const nInLen = sB64Enc.length;
    // tslint:disable-next-line: no-bitwise
    const nOutLen = nBlocksSize ? Math.ceil((nInLen * 3 + 1 >> 2) / nBlocksSize) * nBlocksSize : nInLen * 3 + 1 >> 2;
    const taBytes = new Uint8Array(nOutLen);

    for (let nMod3: number, nMod4: number, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
      // tslint:disable-next-line: no-bitwise
      nMod4 = nInIdx & 3;
      // tslint:disable-next-line: no-bitwise
      nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << 18 - 6 * nMod4;
      if (nMod4 === 3 || nInLen - nInIdx === 1) {
        for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
          // tslint:disable-next-line: no-bitwise
          taBytes[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
        }
        nUint24 = 0;
      }
    }
    return taBytes;
  };

  window.veb2023.downloadAs = (filename: string, contentType: string, content: string) => {
    // Blazor marshall byte[] to a base64 string, so we first need to convert the string (content) to a Uint8Array to create the File
    const data = base64DecToArr(content);

    // Create the URL
    const file = new File([data], filename, { type: contentType });
    const exportUrl = URL.createObjectURL(file);

    // Create the <a> element and click on it
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = exportUrl;
    a.download = filename;
    a.target = '_blank';
    a.click();

    // schedule cleanup
    setTimeout(() => {
      if (a.parentNode) {
        a.parentNode.removeChild(a);
      }
    }, 15000);

    // We don't need to keep the url, let's release the memory
    URL.revokeObjectURL(exportUrl);
  };

  const msalConfig = {
    auth: {
      clientId: '2f00debe-7432-4729-aa60-bad84a611033',
      authority: 'https://login.microsoftonline.com/1e61f199-cb0e-4e1c-a96b-37f128968d09',
      redirectUri: window.location.hostname === 'localhost' ? window.location.origin : `${window.location.origin}/auth-callback`
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
  };

  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  const loginRequest = {
    scopes: ['openid', 'profile']
  };

  let myMSALObj: any;

  window.veb2023.adLogin = async () => {
    if (!myMSALObj) {
      myMSALObj = new (<any> window).msal.PublicClientApplication(msalConfig);
    }
    const currentAccounts = myMSALObj.getAllAccounts();
    if (currentAccounts && currentAccounts[0] && currentAccounts[0].username) {
      const request = { ...loginRequest, account: myMSALObj.getAccountByUsername(currentAccounts[0].username) };
      try {
        const resp = await myMSALObj.acquireTokenSilent(request);
        if (resp !== null) {
          return resp.accessToken;
        } else {
          throw new Error('Login popup returned no response.');
        }
      } catch (exn) {
        console.warn(exn);
      }
    }
    const response = await myMSALObj.loginPopup(loginRequest);
    if (response !== null) {
      return response.accessToken;
    } else {
      throw new Error('Login popup returned no response.');
    }
  };

  window.veb2023.adLoginComplete = () => {
    // tslint:disable-next-line: no-unused-expression
    new (<any> window).msal.UserAgentApplication(msalConfig);
  };

}());